import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  OrderStatus,
  isBillingStatusNotBilled,
  isBillingStatusApprovedByDispatcher,
  isBillingStatusApprovedByLmo,
  isBillingStatusBilled,
  isBillingStatusApproved,
  isCancelled,
  isComplete,
  isInProgressGroup,
} from '~v2Models/order.model';
import { BehaviorSubject, Observable, Subject, of, combineLatest } from 'rxjs';
import { debounceTime, switchMap, take } from 'rxjs/operators';
import { environment } from '~environments/environment';
import { UserService } from '~services/user.service';
import { AuthService } from '~services/auth.service';

export interface SearchResultFromServer {
  id: number;
  saUniqueId: string;
  orderStatus: OrderStatus;
  bolNumber?: string;
  fracId: number;
  fracName: string;
  billingStatus: 'approved_by_lmo' | 'approved' | 'approved_by_dispatcher' | 'not_billed' | 'billed';
  accountId: number;
  accountName: string;
  ticketNumber: string;
}

export interface SearchResult extends SearchResultFromServer {
  routerLink: string[];
  prettyStatusName: string;
  prettyBillingStatusName?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderUniqueIdSearchService {
  private searchText$$ = new BehaviorSubject<string>('0');
  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchResults$$ = new BehaviorSubject<SearchResult[]>([]);
  private open$$ = new Subject<null>();

  public get searchResults$(): Observable<SearchResult[]> {
    return this.searchResults$$.asObservable();
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$.asObservable();
  }

  public get open$(): Observable<null> {
    return this.open$$.asObservable();
  }

  constructor(private http: HttpClient, private userService: UserService, private authService: AuthService) {
    this.listenToSearchTextChange();
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  public open() {
    this.open$$.next();
  }

  private listenToSearchTextChange() {
    combineLatest(this.searchText$$.pipe(debounceTime(50)), this.authService.isLoggedIn$)
      .pipe(
        switchMap(([searchText, isLoggedIn]) => {
          if (!isLoggedIn) {
            return of(null);
          }
          if (!searchText || searchText.length < 4) {
            return of(null);
          }
          this.loading$$.next(true);
          return this.http.get<SearchResultFromServer[]>(
            `${environment.api}/orders/unique_id_search?searchText=${searchText}`,
          );
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        if (!results) {
          this.searchResults$$.next([]);
          return;
        }
        const accountType = this.userService.isLMOAccount()
          ? 'lmo'
          : this.userService.isDispatcherAccount()
          ? 'dispatcher'
          : 'N/A';
        this.searchResults$$.next(results.map(attachLocalInfo(accountType, this.userService.isAdmin())));
      });
  }
}

function attachLocalInfo(
  accountType: 'lmo' | 'dispatcher' | 'N/A',
  isAdmin: boolean,
): (SearchResultFromServer) => SearchResult {
  return (searchResult: SearchResultFromServer): SearchResult => {
    if (isComplete(searchResult) && isAdmin) {
      return {
        ...searchResult,
        prettyStatusName: getPrettyStatus(searchResult),
        routerLink: getBillingUrl(searchResult),
        prettyBillingStatusName: getPrettyBillingStatus(searchResult),
      };
    }
    if (accountType === 'lmo') {
      return {
        ...searchResult,
        prettyStatusName: getPrettyStatus(searchResult),
        routerLink: ['/', 'lmo', 'frac', `${searchResult.fracId}`, 'orders', `${searchResult.id}`],
      };
    }
    if (accountType === 'dispatcher') {
      return {
        ...searchResult,
        prettyStatusName: getPrettyStatus(searchResult),
        routerLink: ['/', 'dispatcher', 'orders', `${searchResult.id}`],
      };
    }
    return {
      ...searchResult,
      prettyStatusName: getPrettyStatus(searchResult),
      routerLink: ['/'],
    };
  };
}

function getBillingUrl(searchResult: SearchResultFromServer): string[] {
  const urlStatus = isBillingStatusNotBilled(searchResult)
    ? 'needDataForTicketing'
    : isBillingStatusApprovedByDispatcher(searchResult)
    ? 'waitingForTicketApproval'
    : isBillingStatusApprovedByLmo(searchResult)
    ? 'readyForInvoicing'
    : isBillingStatusBilled(searchResult)
    ? 'waitingForInvoiceApproval'
    : isBillingStatusApproved(searchResult)
    ? 'invoiceApproved'
    : null;
  return ['/', 'fracs', `${searchResult.fracId}`, 'orders', urlStatus, `${searchResult.id}`];
}

function getPrettyStatus(searchResult: SearchResultFromServer): string {
  if (isComplete(searchResult)) {
    return 'completed';
  }
  if (isCancelled(searchResult)) {
    return 'cancelled';
  }
  if (isInProgressGroup(searchResult)) {
    return 'In Progress';
  }
  return 'Pending';
}

function getPrettyBillingStatus(searchResult: SearchResultFromServer): string {
  if (!isComplete(searchResult)) {
    return null;
  }
  return isBillingStatusNotBilled(searchResult)
    ? 'Awaiting Loader Data'
    : isBillingStatusApprovedByDispatcher(searchResult)
    ? 'Waiting for Ticket Approval'
    : isBillingStatusApprovedByLmo(searchResult)
    ? 'Ready for Invoicing'
    : isBillingStatusBilled(searchResult)
    ? 'Waiting for Invoice Approval'
    : isBillingStatusApproved(searchResult)
    ? 'Invoice Approved'
    : null;
}
