<ng-template #modal>
  <div class="order-unique-id-search">
    <div class="search-bar">
      <mat-form-field class="full-width">
        <input
          matInput
          placeholder="Search (minimum 4 characters)"
          type="text"
          autocomplete="off"
          [(ngModel)]="searchTerm"
          (keydown.enter)="goToFirstEntry()"
        />
        <mat-spinner diameter="24" matSuffix *ngIf="loading$ | async"></mat-spinner>
      </mat-form-field>
    </div>
    <div class="search-results">
      <div class="search-result" *ngFor="let order of searchResults$ | async; trackBy: trackBy" [@fadeInAndOut]>
        <a
          [routerLink]="order.orderStatus === 'completed' ? ['/', 'invoicing', 'order', order.id] : order.routerLink"
          (click)="close()"
          *ngIf="optedInToNewBillingPortal; else optedOut"
        >
          {{ order.orderStatus }}
          <div class="header">
            <div class="unique-id" [innerHTML]="order.saUniqueId | highlight: searchTerm"></div>
            <div class="bol-number" *ngIf="order.bolNumber">
              BOL: <span [innerHTML]="order.bolNumber | highlight: searchTerm"></span>
            </div>
            <div class="bol-number" *ngIf="order.ticketNumber">
              Ticket No: <span [innerHTML]="order.ticketNumber | highlight: searchTerm"></span>
            </div>
          </div>

          <div class="order-body">
            <div class="status">
              {{ order.prettyStatusName | titlecase }}
              <span *ngIf="order.prettyBillingStatusName">({{ order.prettyBillingStatusName }})</span>
            </div>
          </div>
          <div class="account">
            {{ order.accountName }}
          </div>
        </a>
        <ng-template #optedOut>
          <a [routerLink]="order.routerLink" (click)="close()">
            <div class="header">
              <div class="unique-id" [innerHTML]="order.saUniqueId | highlight: searchTerm"></div>
            </div>

            <div class="order-body">
              <div class="status">
                {{ order.prettyStatusName | titlecase }}
                <span *ngIf="order.prettyBillingStatusName">({{ order.prettyBillingStatusName }})</span>
              </div>
            </div>
            <div class="account">
              {{ order.accountName }}
            </div>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
